// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import Rails from "@rails/ujs"
//import Turbolinks from "turbolinks"
//import * as ActiveStorage from "@rails/activestorage"
//import "channels"

//Rails.start()
//Turbolinks.start()
//ActiveStorage.start()

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
//require("jquery")
import JQuery from 'jquery';
window.$ = window.jQuery = window.JQuery = JQuery;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

require("popper.js")
require("bootstrap")
// require("bootstrap-fileinput")
// require("clipboard")
require("@fortawesome/fontawesome-free")
require('./product')
require('./search')
require('./admin/email_templates')
require('./admin/emails')

const images = require.context('../images/', true)

import "../src/stylesheets/application.scss"
