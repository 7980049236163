$(document).on('turbolinks:load', function(){
  $('#card-body-results').on('click', '.to_expand', function(){
    var email_id = $(this).attr('data-id');
    var page_type = $(this).attr('data-page');

    if ($(this).attr('aria-expanded')=='true') {
      return;
    }

    var url = "/admin/emails/" + email_id + "/edit";
    if (page_type == 'show') {
      url = "/admin/emails/" + email_id + "/";
    }

    $.ajax({
      url: url,
      type: 'get',
      cache: false,
    }).done(function(data, textStatus, jqXHR) {
      $("#email_form_" + email_id).html(data);
    });
    return;
  });

  $('a#create_emails').click(function(e){
    e.preventDefault();

    var ids = checked_ids();
    var email_template_id = $('#email_template_id').val();

    if (ids.length<1) {
      alert('メール送信ユーザを選択してください。');
      return;
    }

    if (!email_template_id) {
      alert('メールテンプレートを選択してください。');
      return;
    }

    console.log("user_ids: " + ids.join(",") + ", email_template_id: " + email_template_id);
    var data = {};
    data['user_id'] = ids;
    data['email_template_id'] = email_template_id;
    $.ajax({
      url: "/admin/emails/create_for_users",
      type:'post',
      dataType:'script',
      data: data,
      beforeSend: function(xhr) {
        xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr('content'))
      },
    }).done(function( data, textStatus, jqXHR ) {
      alert(data);
    }).fail(function( data, textStatus, errorThrown) {
    });
  });

  $('#card-body-results').on('click', '.check_all_for_delete', function () {
    $('.check_all_for_delete').prop('checked', $(this).is(':checked'));
    $('.check_each_for_delete').prop('checked', $(this).is(':checked'));
  });

  $('#card-body-results').on('click', '.check_each_for_delete', function () {
    var n = $(this).attr('name');
    var c = $(this).is(':checked');
    $('.check_each_for_delete[name=' + n + ']').prop('checked', c);
    if (!c) {
      $('.check_all_for_delete').prop('checked', false);
    }
  });

  $('#card-body-results').on('click', '#send_emails', function () {
    var ids = checked_ids();

    if (ids.length<1) {
      alert('送信するメールを選択してください。');
      return;
    }

    if (confirm('メールを一括送信しますか？')){
      var data = {};
      data['id'] = ids;

      $.ajax({
        url:'/admin/emails/send_emails',
        type:'post',
        dataType:'json',
        data: data,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr('content'))
        },
      })
      .done(function( data, textStatus, jqXHR ) {
        alert(JSON.stringify(data.result)+'通のメールを送信しました。');
        window.location.href = '/admin/emails';
      })
    }
    return;
  });

  $('#card-body-results').on('click', '#delete_emails', function (e) {
    e.preventDefault();
    var ids = checked_emails_for_delete();
    if (ids.length<1) {
      alert('削除するメールを選択してください。');
      return;
    }

    if (confirm('メールを一括削除しますか？')) {
      var data = {};
      data['id'] = ids;
      $.ajax({
        url:'/admin/emails/delete_emails',
        type:'post',
        dataType:'json',
        data: data,
        beforeSend: function(xhr) {
          xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr('content'))
        },
      })
      .done(function( data, textStatus, jqXHR ) {
        var ids = data.result;
          ids.forEach(function(value, index, ary){
          var id = value;
          $('tr#email_summary_'+id).hide(100);
          $('tr#email_detail_'+id).hide(100);
          $('tr#email_summary_'+id).remove();
          $('tr#email_detail_'+id).remove();
        });
      })
    }

    return;
  });

  function checked_ids() {
    return $('[class="check_each"]:checked').map(function(){
      console.log($(this).val());
              return $(this).val();
            }).get();
  }

  function checked_emails_for_delete() {
    return $('[class="check_each_for_delete"]:checked').map(function(){
              return $(this).val();
            }).get();
  }
});
