$(document).on("turbolinks:load", function() {
  $('table#product-list').on('click', 'td.product-no', function(){
    var product_id = $(this).attr('data-id');
    console.log('clicked '+ product_id);
    if ($(this).attr('aria-expanded')=="true") {
      return;
    }
    
    $.ajax({
      url: "/admin/products/" + product_id + "/images",
      type:'get',
      dataType:'script'
    })
      .done(function( data, textStatus, jqXHR ) {
    });
  });
});