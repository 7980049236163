$(document).on('turbolinks:load', function() {
  $('#card-body-results').on('click', '.check_all', function(){
    $('.check_all').prop('checked', $(this).is(':checked'));
    $('.check_each').prop('checked', $(this).is(':checked'));
  });

  $('#card-body-results').on('click','.check_each',function() {
    var n = $(this).attr('name');
    var c = $(this).is(':checked');
    $('.check_each[name=' + n + ']').prop('checked', c);
    if (!c) {
      $('.check_all').prop('checked', false);
    }
  });

  $('.nav-tabs a').click(function(e){
    e.preventDefault();
    $(this).tab('show');
  });

  function decide_tab() {
    var dec_url = decodeURIComponent(window.location.href);
    if (dec_url.indexOf('words_form[detail]=1')>=0) {
      $('.nav-tabs a[data-target="#detail-search"]').tab('show')
    }
    else {
      $('.nav-tabs a[data-target="#simple-search"]').tab('show')
    }
  }

  decide_tab();

  // 検索結果一覧画面のブランド名
  // Tooltip
  function setTooltip(btn, message) {
    $(btn).tooltip('show')
      .attr('data-original-title', message)
      .tooltip('show');
  }

  function hideTooltip(btn) {
    setTimeout(function() {
      $(btn).tooltip('hide');
    }, 1000);
  }

  // Clipboard
  var clipboard = new ClipboardJS('.clipboard-btn');

  clipboard.on('success', function(e) {
    setTooltip(e.trigger, 'コピーしました!');
    hideTooltip(e.trigger);
  });

  $('a#download-checked').click(function(e){
    e.preventDefault();

    var ids = checked_ids();

    if (ids.length<1) {
      alert('ダウンロードする商品を選択してください。');
      return;
    }
    if (ids.length>100) {
      alert('ダウンロードする商品数は100が上限です。');
      return;
    }

    var frm = $('form#download-checked-form')
    $.each(ids, function(index, id) {
      var hidden = $('<input>').attr('name', 'id[]').attr('type', 'hidden').val(id);
      frm.append(hidden);
    });
    frm.submit();
    frm.find("input[name='id[]']").remove();
  });

  function checked_ids() {
    return $('input.check_each:checked').map(function(){
      return $(this).val();
    });
  }

  function checked_params_ids() {
    return $('input.check_each:checked').map(function(){
                  return 'id[]='+$(this).val();
                }).get().filter(function (x, i, self) {
                  return self.indexOf(x) === i;
                }).join('&');
  }
});
