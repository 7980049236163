$(document).on('turbolinks:load', function(){
  $('#email_templates-table').on('click', '.email-template-id', function(){
    var email_template_id = $(this).attr('data-id');
    if ($(this).attr('aria-expanded')=='true') {
      $(this).text('編集');
      return;
    }
    $(this).text('閉じる');
    $.ajax({
      url: "/admin/email_templates/" + email_template_id + "/edit",
      type: 'get',
      cache: false,
    }).done(function(data, textStatus, jqXHR) {
      $("#email_template_column_div_" + email_template_id).html(data);
    });
    return;
  });
});
